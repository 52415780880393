import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 9`}</h1>
    <p>{`This week contains music by Ocoeur, futuristic electro with Nite Fleit, Psychedelic sounds by Elijah, Wedding Acid Group with their first output of the new year and James Shinra returns to AF.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2823164384/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://n5md.bandcamp.com/album/everything">Everything by Ocoeur</a>
    </iframe>
    <h1>{`Ocoeur - Everything`}</h1>
    <p>{`The brainchild of French electronic pioneer Franck Zaragoza, Ocoeur, is back with a fresh album. Dreamy soundscapes and sync-based ambient tones.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://n5md.bandcamp.com/album/everything"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2481666160/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://nitefleit.bandcamp.com/album/dance-trax-vol-26-d-tiffany-jensen-interceptor-remixes">
    Dance Trax Vol.26 + D.Tiffany &amp; Jensen Interceptor Remixes by Nite Fleit
  </a>
    </iframe>
    <h1>{`Nite Fleit - Dance Trax Vol.26`}</h1>
    <p>{`Futuristic electro with high octanes.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://nitefleit.bandcamp.com/album/dance-trax-vol-26-d-tiffany-jensen-interceptor-remixes"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=1053833627/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://contagiondiscs.bandcamp.com/album/augmented">
    Augmented by Elijah
  </a>
    </iframe>
    <h1>{`Elijah - Augmented`}</h1>
    <p>{`Kansas City-based Elijah released a psychedelic dance floor album.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://contagiondiscs.bandcamp.com/album/augmented"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=2536650221/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://art-aud.bandcamp.com/album/easid-bits-ep">
    Easid Bits EP by Wedding Acid Group
  </a>
    </iframe>
    <h1>{`Wedding Acid Group - Easid Bits EP`}</h1>
    <p>{`Acid boyband, Wedding Acid group with their first output of the new year. Be sure to check the EOD remix as well!`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://art-aud.bandcamp.com/album/easid-bits-ep"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=545363582/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://analogicalforce.bandcamp.com/album/af029-reverie-ep">
    [AF029] Reverie EP by James Shinra
  </a>
    </iframe>
    <h1>{`James Shinra - Reverie EP`}</h1>
    <p>{`James Shinra returns to AF with a 4-tracker EP. Dive into Shinra’s world of dreamy electro.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://analogicalforce.bandcamp.com/album/af029-reverie-ep-ep"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      